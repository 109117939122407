import { render, staticRenderFns } from "./InsLayer.vue?vue&type=template&id=bb8a3f1c&scoped=true&"
import script from "./InsLayer.vue?vue&type=script&lang=ts&"
export * from "./InsLayer.vue?vue&type=script&lang=ts&"
import style0 from "./InsLayer.vue?vue&type=style&index=0&lang=less&"
import style1 from "./InsLayer.vue?vue&type=style&index=1&id=bb8a3f1c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb8a3f1c",
  null
  
)

export default component.exports