export default {
  lang: 'English',
  test: 'test',
  AddToCart: 'Add To Cart',
  changeLangSuccess: 'Language switch successful !',
  changeCurSuccess: 'Currency switch successful !',
  Footer: {
    Branch: 'Branch',
    Address: 'ADDRESS',
    Tel: 'TEL',
    AddressText1: 'Sunny House, 270A Kings Road, North Point',
    AddressText2: 'Lien Hong Apartments, 116-122&122A Belcher’s street, Kennedy Town',
    OpeningHours: 'OPENING HOURS',
    OpeningHoursText1: 'Mon – Sun (09:00am - 09:00pm)',
    OpeningHoursText2: 'Mon – Sun (09:00am - 09:00pm)'
  },
  Input: {
    email: 'Please enter the correct email',
    number: 'Please enter a number',
    input: 'Please input ',
    text: '',
    date: 'Please enter a date',
    password: 'Two inconsistent input passwords',
    textarea: '',
    emailNotFound: 'Email Not Found',
    logopassword: 'Please input passwords',
    confirmpassword: 'Two inconsistent input passwords',
    nomessage: 'No Message',
    phoneincorrect: 'The phone number format is incorrect'
  },
  Message: {
    Logout: 'Timeout ! login now ?',
    Message: 'Message',
    Confirm: 'Ok',
    Cancel: 'Close',
    SucceedInOperating: 'Succeed in Operating',
    MemberInfo: 'Your personal information is not perfect, which may affect your access to the offer',
    msg400: '400 System busy!',
    msg401: '401 System busy!',
    msg403: '403 System busy!',
    msg404: 'Not found!',
    msg500: '500 System busy!',
    msg503: '503 System busy!',
    msg502: '502 bad gataway!',
    LearnMore: 'Learn More',
    View: 'View More',
    Savedsuccessfully: 'Saved successfully',
    Loading: 'Page Loading, Please Wait',
    ViewDetail: 'VIEW DETAILS',
    FollowUs: 'Follow Us',
    Evidence: 'Evidence',
    UploadEvidence: 'Upload Evidence',
    OrderNumber: 'Order Number',
    Remark: 'Remark',
    Image: 'Image',
    SubmitSuccess: 'Submitted Successfully',
    SubmitFailed: 'Submit Failed',
    DeliveryDate: 'Delivery Date',
    DeliveryTime: 'Delivery Time',
    DeliveryInstructions: 'Delivery Instructions',
    DeliveryMark: 'Please make a reservation one day in advance. The order is cut off at 10 PM every day. The Package over 800 packages, urban delivery.Only Payment complete before 10PM can be delivered the next day'
  },
  Account: {
    MemberInformation: 'Member Information',
    MyMessages: 'My Messages',
    MyFavorite: 'My Favorite',
    MyOrder: 'My Order',
    DeliveryAddress: 'Delivery Address',
    Logout: 'Logout',
    Welcome: 'WELCOME!',
    SISA: 'Succeed in Choosing',
    SignIn: 'SIGN IN',
    OurShop: 'OUR SHOP',
    MemDiscount: 'Membership Discount',
    MyAccount: 'My Account',
    OrderHistory: 'Order History',
    IntegralReward: 'Integral Reward',
    ECoupon: 'E-Coupon',
    ChangePsd: 'Change Password',
    MemberCenter: 'Member Center'
  },
  product: {
    ProductCode: 'Product Code',
    buy: 'Buy',
    addToCart: 'Add To Cart',
    buyNow: 'Buy now',
    favorite: 'Favorite',
    priceTitle: 'Price',
    countTitle: 'Count',
    youWouldLike: 'some products you would like',
    moreTips: 'Click to load more',
    none: 'None',
    noneInCat: 'no prodcut belong to this catalog',
    notifyMe: 'Notify Me',
    purchasing: 'Purchasing',
    comments: { title: 'Comments' },
    detail: 'Detail',
    overView: 'overView',
    specification: 'Specification',
    attr: { 0: 'Size', 1: 'Color' },
    SaleOut: 'Sold Out',
    offSale: 'off sale',
    Recommend: 'Recommend',
    Remarks: 'Remarks',
    EnterRemaks: 'please enter remarks',
    up: 'Last',
    down: 'Next',
    logouted: 'you are logouted!',
    loginow: 'login now',
    successInAdding: 'succeed in adding',
    successInRemoving: 'succeed in removing',
    ProductIntro: 'Product introduction',
    UserReviews: 'User reviews',
    OwnerReply: 'Owners reply',
    per: 'page',
    page: '',
    NoProducts: 'No Products'
  },
  Shoppingcart: {
    ShoppingcartTitle: 'Shopping Cart',
    Cart: 'CART',
    Merchant: 'Merchant',
    Product: 'Product',
    Quantity: 'Quantity',
    Price: 'Price',
    Total: 'Total',
    CheckoutList: 'Check Out',
    Checkout: 'Check Out',
    NoItems: 'No item in the Shopping Cart',
    cartSubtotal: 'Cart Subtotal',
    GoToCart: 'Go To Cart',
    VAT: '(VAT included)',
    None: 'No article in your Shopping Cart!Shopping now.',
    more: 'more',
    hide: 'hide'
  },
  Login: {
    doLogin: 'Login',
    LoginTitle: 'Login',
    NewUser: 'New buyer',
    RegNow: 'Register now',
    ForgetPwd: 'Forget Password?',
    RememberMe: 'Remember Me',
    FaceBookUse: 'Login with FaceBook',
    UserName: 'Login Name/Email',
    EnterEmail: 'Please Enter The Email',
    CorrectEmail: 'Please Enter The Correct Email',
    LoginPwd: 'Please enter your password',
    LoginoldPwd: 'Incorrect password',
    Loginerror: 'Incorrect account or password',
    LoginSucceeded: 'Login Succeeded'
  },
  Register: {
    or: 'or',
    RegisterTitle: 'Registration',
    UserEmail: 'Email',
    UserRegPassword: 'Password',
    UserNewPassword: 'New Password',
    UserRegTips: 'Min.6 characters with letters and numbers',
    UserConfirmPassword: 'Confirm',
    UserFirstName: 'First Name',
    UserLastName: 'Last Name',
    UserContactNumber: 'Phone',
    UserBirthDate: 'Birth Date',
    UserBirthDateTip: 'Birth Date (MM/DD)',
    UserGender: 'Gender',
    UserMale: 'Male',
    UserFemale: 'Female',
    UserLanguage: 'Language',
    UserLanguageE: 'English',
    UserLanguageT: 'Traditional Chinese',
    UserLanguageS: 'Simplified Chinese',
    RegisterBtn: 'Register',
    RegisterAgree: 'I agree to the terms of purchase and terms of use',
    RegisterEmail: 'Email cannot be empty',
    RegisterEmail01: 'Please enter a formal mailbox format',
    RegisterPassword: 'Please enter your password',
    RegisterNewPassword: 'Please enter your password again',
    RegisterNewPassword01: 'Two inconsistent input passwords',
    RegisterFirstName: 'Please enter your name',
    RegisterLastName: 'Name please enter your name',
    RegisterAgree_check: 'Please check',
    RegisterMobile: 'Please enter your phone',
    Registerdigital: 'Length from 8 to 20 must contain uppercase letters, lowercase letters and numbers'
  },
  DeliveryAddress: {
    AddAddress: 'Add address',
    ChooseDeliveryAddress: 'Select delivery address',
    EditBtn: 'Edit',
    DeleteBtn: 'Delete',
    AddDeliveryAddress: 'New Address',
    PostalCode: 'PostalCode',
    Area: 'Area',
    Province: 'Province',
    District: 'District',
    Address: 'Address',
    DefaultAddress: 'Set as default delivery address?',
    SaveBtn: 'Save',
    ResetBtn: 'Reset',
    CloseBtn: 'Close',
    YesBtn: 'Yes',
    NoBtn: 'No',
    AddFirstName: 'First Name',
    AddLastName: 'Last Name',
    UserContactNumber: 'Phone',
    Mobile: 'Mobile',
    Addressprovince: 'Please choose the province',
    AddressArea: 'Please choose the area',
    Detail: 'detail'
  },
  MyCoupon: {
    MyCoupon: 'My Coupon',
    Coupon: 'Coupon',
    PeriodOfValidity: 'Time',
    NoCoupon: 'No Coupon',
    CouponStatus: 'Coupon Status',
    SelectCouponStatus: 'Please Slect Coupon Status',
    TotalNum: 'Total',
    Piece: 'Piece',
    NotUse: 'Invalid',
    Used: 'Valid',
    Among: 'Among',
    All: 'All'
  },
  MyFavorite: {
    MyFavorite: 'My Favorite',
    LearnMore: 'Learn More',
    RemoveProduct: 'Remove Product',
    RemoveSuccess: 'Remove Successfully'
  },
  Notification: {
    MyMessages: 'My Messages',
    MsgList: 'Message List',
    TotalRecQtyWithSymbol: 'Total Qty. ：',
    UnreadWithSymbol: 'Unread Qty：',
    MsgUnreadOnly: 'Unread Message Only',
    MsgMarkAsRead: 'Mark as read',
    CheckAllOrNot: 'Check All/Not',
    MsgSender: 'Sender',
    MsgSubject: 'Subject',
    MsgSenderDate: 'Send Date',
    MsgReadStatus: 'Status',
    Details: 'Details',
    More: 'More',
    Back: 'Back',
    MsgContent: 'Content',
    Send: 'Send',
    FollowUs: 'FOLLOW US',
    WeAccept: 'WE ACCEPT'
  },
  Forgetpassword: {
    ForgetPassword: 'Forget Password',
    ResetPwdTips:
      'Please enter the registered email you used for registration, then click Next to continue',
    LoginNameEmail: 'Login Name/Email',
    NextStep: 'Next',
    ResetPwdSuccess: 'Temp Password is sended to your email,please check.',
    NotEmail: 'Cannot receive the Activate Email',
    ToReEnter: 'Click here to re-enter',
    EnterEmail: 'Please Enter The Email',
    CorrectEmail: 'Please Enter The Correct Email',
    ResetPwd: 'Reset Password'
  },
  MemberInfo: {
    MemberInfoTitle: 'MemberInfo',
    UserAccount: 'User Account',
    ModifyPassword: 'ModifyPassword',
    EnterUserName: 'Please Enter Fristname',
    EnterUserLastName: 'Please Enter Lastname',
    EnterUserPhone: 'Please Enter Phone',
    EnterUserBrithDate: 'Please Select BrithDate',
    EnterOldPwd: 'Please Enter Old Password',
    EnterNewPwd: 'Please Enter New Password',
    EnterNumber: 'required number',
    EnterComfirmPwd: 'Please Enter Comfirm Password',
    PwdNotCorrect: 'The passwords not match twice',
    DDMM: 'DD/MM'
  },
  Order: {
    Fail: 'Pay Fail',
    OrderTitle: 'My Order',
    OrderDetail: 'Order Detail',
    OrderPay: 'Pay Now',
    OrderCancel: 'Order Cancel',
    OrderComplete: 'Order Complete',
    OrderClose: 'Order Close',
    CartSubtotal: 'Cart Subtotal',
    DeliveryCharge: 'Delivery Charge',
    TotalPrice: 'TotalPrice',
    OrderStatus: 'Order Status',
    OrderDateTime: 'Order Date & Time',
    OrderNumber: 'Order Number',
    OrderSearch: 'Order Search',
    EnterProductName: 'Please Enter Product Name',
    Items: 'ITEMS',
    OrderComment: 'Comment',
    PromotionCode: 'Promotion Code',
    RecommendationCode: 'Recommendation Code',
    CommodityPrice: 'Commodity Price',
    Full: 'Full',
    Minus: 'Minus',
    Hit: 'Hit',
    Precent: 'Precent off',
    Discount: 'Discount',
    Cancel: 'cancel'
  },
  Payment: {
    ReturnHomePage: 'Return HomePage',
    PayStatus: 'Pay Status',
    PayFailTips:
      'The payment was fail, pleasse try again after checking of your account.',
    PaySuccessTips: 'The payment was successful.'
  },
  CheckOut: {
    Ordersummary: 'Order Summary',
    Input: 'Input',
    expiryDate: 'Expiry Date',
    awsl: 'awsl',
    expressError: 'Express outage!Please choose others.',
    pay: 'pay',
    CheckOutTitle: 'Check Out',
    Merchant: 'Merchant',
    Product: 'Product',
    Qty: 'Qty',
    Price: 'Price',
    SplitDelivery: 'Split Delivery',
    ShippingMethod: 'Shipping Method',
    PleaseSelect: 'Please select',
    Selected: 'Selected',
    Name: 'Name',
    Phone: 'Phone',
    Address: 'Address',
    Pickup: 'Pick up',
    Change: 'Change',
    DeliveryCharge: 'Delivery Charge',
    SubTotal: 'Sub-Total',
    PayBy: 'Pay By',
    Couponcode: 'Promotion Code',
    confirm: 'Confirm',
    Total: 'Total',
    BacktoShoppingCart: 'Back to Shopping Cart',
    Last: 'Last',
    Checkout: 'Check Out',
    Next: 'Next',
    Delivery: 'Delivery',
    SelectDeliveryAddress: 'Select delivery address',
    LastName: 'Last Name',
    Edit: 'Edit',
    Delete: 'Delete',
    Select: 'Select',
    NewAddress: 'New Address',
    PickUp: 'Pick Up',
    FirstName: 'First Name',
    ZipCode: 'Zip Code',
    Mobile: 'Mobile',
    Country: 'Area',
    Province: 'Region / Province',
    City: 'District',
    Close: 'Close',
    PickupDate: 'Pick up Date',
    PickupTime: 'Pick up Time',
    Morning: 'Morning',
    Afternoon: 'Afternoon',
    PickAddressError: 'Incomplete PickAddress information',
    AddressLock: 'This Address has been Selected',
    CompanyName: 'CompanyName',
    CompanyPhone: 'CompanyPhone',
    CompanyAddress: 'CompanyAddress',
    PickupAddress: 'Pick up Address',
    Save: 'Save',
    PleaseChoose: 'Please Choose',
    PleaseSelectdate: 'Please Select date',
    PleaseSelecttime: 'Please select time',
    ConfirmationOfOrder: 'Confirmation Of Order',
    Concessions: 'Concessions',
    PaymentMethod: 'Payment Method',
    Complete: 'Complete',
    OrderPreview: 'Order Preview',
    CandP: 'Cant use coupon and promotionCode at the same time.',
    promotionCodeError: 'Please enter the correct promotion code',
    Use: 'Use',
    As: 'As',
    PaymentMethodError: 'The payment method has not been selected yet!',
    OrderSent: 'Order has been sent',
    PaymentProcess: 'Please complete the payment process'
  },
  Concessions: {
    Cannot: 'Offer cannot be used at the same time'
  },
  Address: {
    Country: 'Please choose country',
    Province: 'Please choose province',
    Address: 'Please enter detail address'
  },
  MemberPoints: {
    MemberPoints: 'Member Points',
    PointsDetail: 'Points Detail',
    PointsIncome: 'Points Income',
    PointsSpending: 'Points Spending',
    OrderTime: 'Order Time',
    OrderNo: 'Order Number',
    Income: 'Income',
    Spending: 'Expenditure',
    IncomeSpending: 'Income/Spending',
    DetailedDescription: 'Detailed Description',
    TotalPoints: 'Total Integral',
    Hello: 'Hello',
    Equals: 'Equals',
    Integral: 'Integral',
    PointsTips: 'Frequently asked questions about integral use',
    PointsType: 'Points Type',
    Points: 'Points',
    PointsMode: 'Points Mode'
  },
  Cms: {
    AboutUS: 'About US',
    Event: 'Event',
    Blog: 'Blog',
    News: 'News',
    Product: 'Product'
  },
  promotion: {
    enter: 'enter',
    main: 'The best choice for gifted children',
    monterssori: {
      title1: 'Montessori teaching method',
      title2: 'Develop the habit of independent learning and exploration',
      banner1: 'Be your own teacher',
      banner2: 'Initiative learning',
      banner3: 'build personality'
    }
  },
  RegNPay: {
    BackText: 'Back',
    UploadButtonText: 'Upload files(jpg, png, pdf)',
    UploadingText: 'Uploading...',
    ConfirmText: 'OK',
    UploadSuccessfulText: 'Upload Successful',
    UploadFailText: 'Upload failed',
    NoFileText: 'No file selected',
    UploadLengthText: 'You can only upload {0} files at most',
    UploadSizeText: 'You can only upload {0} M at most',
    PleaseSelect: 'Please Select',
    SubRecord: 'Submission Record',
    ReferenceNum: 'Reference Number',
    SubmissionDate: 'Submission Date',
    Print: 'Print',
    PlsPayWay: 'Please select the mode of payment',
    successful: 'Thank you for your submit',
    reply: 'We will reply you as soon as possible',
    PreviewTitleText: 'Please confirm your information again.',
    FormatErrorText: 'Please input correct email address.',
    RequiredText: 'Required'
  },
  Action: {
    ReturnHomePage: 'Return Homepage',
    OrderDetails: 'Order Details',
    PaymentSuccessTips: 'Payment Successful',
    PaymentFailTips: 'Payment Fail',
    Checkout: 'Check Out',
    Save: 'Save',
    PromotionCodeTips: 'Please enter the PromotionCode'
  },
  home: {
    News: 'News',
    More: 'More',
    Category: 'Category',
    Weaccept: 'We Accept',
    Order: 'Order',
    TelSearch: 'Tel',
    AllProduct: 'All Product',
    ResetProduct: 'Reset',
    Address: 'Address',
    ViewDetail: 'Detail',
    Screening: 'Screening',
    Resetall: 'Reset all filters',
    PriceHL: 'Price from high to low',
    PriceLH: 'Price from low  to high',
    Thatsall: 'That is all',
    Save: 'Save',
    SaveAll: 'Save All'
  }
};
